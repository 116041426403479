.my-exp-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  justify-content: center;
  background-color: black;
  border-radius: 15px;
  margin-block: 10px;
  padding-block: 10px;
  .heading {
    text-align: center;
    font-size: 48px;
    font-family: "Mukta", sans-serif;
    color: white;
    font-weight: 700;
  }
  .workcards {
    margin-block: 50px;
    border: 2px solid white;
    border-radius: 15px;
    padding-block: 50px;
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    // margin-bottom: 15px;
    .logo{
      margin-block: 20px;
    }
    .post {
      margin-block: 20px;
      font-family: "Mukta", sans-serif;
      font-size: 32px;
      color: white;
    }
    .time {
      margin-block: 20px;
      font-family: "Mukta", sans-serif;
      font-size: larger;
      color: white;
    }
  }

  @media screen and (max-width: 1024px) {
    .workcards {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 768px) {
    .workcards {
      flex-direction: column;
      .post{
        font-size: 24px;
      }
    }
  }
}
