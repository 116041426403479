.am-parent {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  .am-content {
    display: flex;
    justify-content: space-around;
    width: 80%;
    .am-left-column {
      height: auto;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .am-text {
        font-family: "Mukta", sans-serif;
        font-size: 3rem;
        color: black;
        .bold-words {
          font-weight: 900;
        }
      }
      .am-subtitle {
        color: black;
        font-family: "Mukta", sans-serif;
        font-size: 1.3rem;
        text-align: justify;
      }
      .sm-links {
        .link-icons {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          text-decoration: none;
          .icons{
            padding: 0.5rem;
            border-radius: 10px;
            font-size: 50px;
            color: white;
            background-color: black;
          }
        }
      }

      @media screen and (max-width: 1440px) {
        .am-text{
          font-size: 2rem;
        }
        .am-subtitle{
          font-size: 1rem;
        }
      }

      @media screen and (max-width: 1024px) {
        .am-text{
          font-size: 1.5rem;
        }
        .am-subtitle{
          font-size: 0.8rem;
        }
      }

      @media screen and (max-width: 768px) {
        .am-text {
          font-size: 1.5rem;
          text-align: center;
        }
        .am-subtitle {
          font-size: 1rem;
        }
        width: 100%;
      }
    }
    .am-right-column {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .right-img {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 768px) {
        .right-img {
          display: none;
        }
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }
}
