.my-details-parent{
    margin-block: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 80%;
    align-items: center;
    .details-content{
        width: 100%;
        .heading{
            font-size: 48px;
        }
        .content-paragraphs{
            text-align: justify;
            font-size: larger;
        }
    }
}