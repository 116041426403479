.mycerts-parent {
  background-color: black;
  width: 80%;
  border-radius: 15px;
  padding-block: 10px;
  margin-block: 10px;
  .heading {
    text-align: center;
    font-family: "Mukta", sans-serif;
    font-size: 48px;
    font-weight: 900;
    color: white;
  }
  .cert-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // align-items: center;
    .cards-parent {
      width: 30%;
      display: flex;
      .cards {
        margin-block: 10px;
        border-radius: 15px;
        padding-block: 20px;
        background-color: white;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cert-title {
          font-size: 20px;
          text-align: center;
          font-weight: 700;
          color: black;
          margin-block: 5px;
        }
        .cert-link {
          text-decoration: none;
          color: black;
          margin-block: 5px;
        }
        .cert-images {
          width: 128px;
          height: 128px;
          margin-block: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .cert-cards {
      flex-direction: column;
      align-items: center;
      .cards-parent {
        width: 90%;
        .cards {
          width: 100%;
        }
      }
    }
  }
}
