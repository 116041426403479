.contact-me-parent {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //   align-items: center;
  .contact-details {
    margin-block: 5px;
    display: flex;
    flex-direction: column;
    width: 40%;
    color: black;
    .title {
      margin-block: 5px;
      font-size: 64px;
      font-weight: 900;
      font-family: "Mukta", sans-serif;
    }
    .sub-title {
      margin-block: 5px;
      font-size: 24px;
      font-family: "Mukta", sans-serif;
    }
    .email {
      margin-block: 5px;
      font-weight: 900;
      font-size: 24px;
      font-family: "Mukta", sans-serif;
    }
    .phone-number {
      margin-block: 5px;
      font-weight: 900;
      font-size: 24px;
      font-family: "Mukta", sans-serif;
    }
  }
  .contact-form {
    margin-block: 5px;
    width: 40%;
    display: flex;
    flex-direction: column;
    .inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      .your-name {
        border-radius: 15px;
        margin-block: 10px;
        border: 2px solid black;
        outline: none;
        font-size: 24px;
        padding-inline: 10px;
        padding-block: 5px;
      }
      .your-email {
        border-radius: 15px;
        margin-block: 10px;
        border: 2px solid black;
        outline: none;
        font-size: 24px;
        padding-inline: 10px;
        padding-block: 5px;
      }
      .your-website {
        border-radius: 15px;
        margin-block: 10px;
        border: 2px solid black;
        outline: none;
        font-size: 24px;
        padding-inline: 10px;
        padding-block: 5px;
      }
      .your-description {
        border-radius: 15px;
        margin-block: 10px;
        height: 200px;
        border: 2px solid black;
        outline: none;
        font-size: 24px;
        padding-inline: 10px;
        padding-block: 5px;
        resize: none;
      }
    }
    .get-in-touch {
      margin-block: 5%;
      width: 100%;
      outline: none;
      text-decoration: none;
      border: 2px solid white;
      border-radius: 15px;
      color: white;
      height: 50px;
      background-color: black;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      .get-in-touch {
        margin-block: 5%;
        width: 100%;
        outline: none;
        text-decoration: none;
        border: 2px solid white;
        border-radius: 15px;
        color: white;
        height: 50px;
        background-color: black;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    .contact-details {
      width: 100%;
      .title {
        font-size: 48px;
      }
      .email {
        font-size: 16px;
      }
      .phone-number {
        font-size: 16px;
      }
    }
    .contact-form {
      width: 100%;
      .inputs {
        .your-email {
          font-size: 16px;
        }
        .your-name {
          font-size: 16px;
        }
        .your-website {
          font-size: 16px;
        }
        .your-description {
          font-size: 16px;
        }
      }
    }
  }
}
