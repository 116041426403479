.myskills-parent {
  display: flex;
  align-items: center;
  width: 80%;
  flex-direction: column;
  .skills-title {
    .title-text {
      font-family: "Mukta", sans-serif;
      font-size: 48px;
      font-weight: 900;
      color: black;
    }
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .list-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    margin-bottom: 5rem;
    .skill-cards {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 13rem;
      height: 13rem;
      background-color: black;
      .skill-images {
        width: 50%;
        height: auto;
        padding-bottom: 1rem;
        padding-top: 1rem;
        color: white;
      }
      .skill-texts {
        font-family: "Mukta", sans-serif;
        font-size: 20px;
        color: white;
      }
    }

    @media screen and (max-width: 1440px) {
      grid-template-columns: auto auto auto auto;
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: auto auto auto;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: auto auto;
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: auto;
    }
  }
}
