.nav-parent {
  display: flex;
  position: inherit;
  justify-content: space-around;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  transition: all 1s ease-in-out;
  height: auto;
  z-index: 5;
  .left-content {
    display: flex;
    justify-content: center;
    width: 20%;
    .Nav-head {
      font-family: "Mukta", sans-serif;
      color: black;
      margin-bottom: 0px;
      display: inherit;
    }
    .Nav-head-pd{
      font-family: "Mukta", sans-serif;
      color: black;
      margin-bottom: 0px;
      margin-left: 10px;
      display: none;
    }
    .hamburger-menu {
      border-radius: 20px;
      border: none;
      background-color: white;
      color: black;
      margin-right: 1rem;
      width: 20%;
      height: auto;
      display: none;
    }
  }
  .middle-content {
    display: flex;
    justify-content: center;
    width: 60%;
    .btns {
      .middle-button {
        text-decoration: none;
        font-family: "Mukta", sans-serif;
        font-size: 1.5rem;
        color: black;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: all 0.3s ease-in-out;
      }
      display: inherit;
      align-items: center;
      border-radius: 20px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      transition: all 0.3s ease-in-out;
    }
    .btns:hover {
      background-color: black;
      .middle-button {
        color: white;
      }
    }
    transition: all 1s ease-in-out;
  }
  .right-content {
    display: flex;
    justify-content: center;
    width: 20%;
    .btn-download {
      background-color: black;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      display: inherit;
      align-items: center;
      border-radius: 5px;
      .resume-download {
        text-decoration: none;
        font-family: "Mukta", sans-serif;
        font-size: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .left-content{
      .Nav-head{
        display: none;
      }
      .Nav-head-pd{
        display: inherit;
      }
    }
    .middle-content{
      justify-content: space-between;
      .btns{
        .middle-button{
          font-size: 14px;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    .left-content {
      width: 100%;
      justify-content: space-between;
      .Nav-head {
        text-align: center;
        margin-left: 1rem;
      }
      .hamburger-menu {
        display: block;
      }
    }
    .middle-content {
      display: none;
    }
    .right-content {
      display: none;
    }
  }
}

.phone-display {
  width: 100%;
  border-bottom: 50px;
  padding-top: 80px;
  padding-bottom: 20px;
  background-color: black;
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  transform: translateY(-500vh);
  .btn-download-pd {
    background-color: white;
    margin-inline: 5%;
    align-items: center;
    justify-content: center;
    width: 90%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    .resume-download-pd {
      text-decoration: none;
      font-family: "Mukta", sans-serif;
      font-size: 1.5rem;
      // padding-left: 1rem;
      // padding-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: black;
    }
  }
  .btns-pd {
    margin-bottom: 20px;
    .middle-button-pd {
      text-decoration: none;
      font-family: "Mukta", sans-serif;
      font-size: 1.5rem;
      color: white;
      padding-left: 1rem;
      padding-right: 1rem;
      transition: all 0.3s ease-in-out;
      border-radius: 20px;
    }
    display: inherit;
    align-items: center;
    border-radius: 20px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    transition: all 0.3s ease-in-out;
  }
  .btns-pd:hover {
    .middle-button-pd {
      background-color: white;
      color: black;
    }
  }
}

@media screen and (max-width: 768px) {
  .responsive-nav {
    transform: none;
  }
}
